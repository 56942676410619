<template>
  <div class="main">
    <!--  筛选区域  -->
    <div class="screens_area " style="margin-top:20px">
      <div>
        <tagsScreen v-for="item in tagsList" :key="item.uuid" :list="item" v-model="tagsDefaultUuid[item.id]"
          @testFn="getTongzhiListData(1)" />
      </div>
    </div>
    <!-- 排序 -->
    <div class="sort_area">
      <div style="margin-left:30px;">
        排序方式：
        <el-radio-group v-model="sortType" size="small" @change="getTongzhiListData(1)">
          <el-radio-button label="0">发文日期</el-radio-button>
          <el-radio-button label="1">截止日期</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <!--  政策通知  -->
    <div :class="{ 'tall': loading }">
      <div v-if="flag" v-loading="loading">
        <div class="zhengce_area" v-for="(item, index) in tongzhiList" :key="item.id">
          <div class="zhengce_title flex row between" @click="goToFormalDetail(item.id)">
            <div v-html="item.title" class="underline"></div>
            <div>></div>
          </div>
          <div class="zhengce_info flex row start mar-r30"
            :style="{ 'margin-bottom': item.formalFile.length == 0 ? '0' : '10px' }">
            <div>{{ item.fwDepartment }}</div>
            <div>{{ item.dispatchDate }}</div>
          </div>
          <div class="foraml_wrap" :style="item.formalFile.length <= 2 ? 'margin-bottom:50px' : ''">
            <div
              class="formal flex row divHover"
              v-for="item2 in item.formalFile.slice(0, 2)" :key="item2.id"
              @click="gotoJieDu(item.id, item2.id)"
              :id="item2.id"
              style="background:#f8f8f8;border:1px solid #e7e7e7"
              >
              <div class="left flex row start mar-r15 neirong" style="flex-wrap: nowrap;">
                <div v-html="item2.subtitle"></div>
                <div v-for="item3 in item2.fuchifangshi" :key="item3" class="zijin">
                  <div :class="[
                    'fuchifangshi',
                    { 'type-1': item3 === '资金扶持' },
                    { 'type-2': item3 === '股权投资' },
                    { 'type-3': item3 === '减税降费' },
                    { 'type-4': item3 === '称号认定' },
                    { 'type-5': item3 === '金融扶持' },
                    { 'type-6': item3 === '其他' },
                  ]" v-if="
                      item3 === '资金扶持' ||
                      item3 === '股权投资' ||
                      item3 === '减税降费' ||
                      item3 === '称号认定' ||
                      item3 === '其他' ||
                      item3 === '金融扶持'
                    ">
                    {{ item3 }}
                  </div>
                </div>
                <div v-if="item2.money > 0" style="color: #8d8d8d" id="zgbt">
                  最高补贴：{{ item2.money }}万
                </div>
              </div>
              <div class="right flex row end mar-l15">

                <div :style="item2.declareDate >= 0 ? 'color:red' : 'color:gray'" class="yjz">
                  {{
                      item2.declareDate == -1
                        ? "【 已截止 】"
                        : item2.declareDate == 0
                        ? "【 今天截止 】"
                        : `【剩 ${item2.declareDate} 天】`
                  }}
                </div>

                <div class="jzsj">
                  截止日期： {{ item2.declareTime }}
                </div>
              </div>

            </div>
          </div>
          <div v-show="item.packUp" class="foraml_wrap">
            <div class="formal flex row"
              v-for="item2 in item.formalFile.slice(2, item.formalFile.length)" :key="item2.id"
              @click="gotoJieDu(item.id, item2.id)" style="background:#f8f8f8;border:1px solid #e7e7e7">
                <div class="left flex row start mar-r15 neirong2">
                  <div class="titlename2" v-html="item2.subtitle">
                  </div>
                  <!-- <el-tooltip class="item_tooltip" effect="dark" placement="bottom">
                    <div slot="content">
                      <span>{{item2.subtitle}}</span>
                    </div>
                  </el-tooltip> -->
                  <div v-for="item3 in item2.fuchifangshi" :key="item3" class="zijin2">
                    <div :class="[
                      'fuchifangshi',
                      { 'type-1': item3 === '资金扶持' },
                      { 'type-2': item3 === '股权投资' },
                      { 'type-3': item3 === '减税降费' },
                      { 'type-4': item3 === '称号认定' },
                      { 'type-5': item3 === '金融扶持' },
                      { 'type-6': item3 === '其他' },
                    ]"
                    v-if="
                        item3 === '资金扶持' ||
                        item3 === '股权投资' ||
                        item3 === '减税降费' ||
                        item3 === '称号认定' ||
                        item3 === '其他' ||
                        item3 === '金融扶持'
                      ">
                      {{ item3 }}
                    </div>
                  </div>
                  <div v-if="item2.money > 0" style="color: #8d8d8d" id="zgbt">
                    最高补贴：{{ item2.money }}万
                  </div>
                </div>
                <div class="right flex row end mar-l15">
                  <div :style="item2.declareDate >= 0 ? 'color:red' : 'color:gray'" class="yjz2">
                    {{
                        item2.declareDate == -1
                          ? "【 已截止 】"
                          : item2.declareDate == 0
                          ? "【 今天截止 】"
                          : `【剩 ${item2.declareDate} 天】`
                    }}
                  </div>
                  <div class="jzsj">
                    截止日期： {{ item2.declareTime }}
                  </div>
                </div>
            </div>
          </div>
          <div class="showHide" v-if="item.formalFile && item.formalFile.length > 2" @click="showHide2($event, index)">
            + 展开更多
          </div>
        </div>
        <div class="line"></div>
        <!--  分页  -->
        <el-pagination
          v-if="tongzhiList.length > 0"
          background
          layout="prev, pager, next"
          :page-size="pageSize"
          :total="pageListTotal"
          :current-page="currentPage"
          @current-change="getTongzhiListData"
          style="margin-top:50px"
        >
        </el-pagination>
      </div>
      <!-- 无内容时的显示 -->
      <div v-else>
        <el-empty description="暂无内容"></el-empty>
      </div>
      
    </div>
    <permission  v-if="dialogVisiblePermission" :dialogVisiblePermission="dialogVisiblePermission" @handleToggle="handleToggle" :typeDialog="typeDialog"
      @handlePermission="handlePermission">
    </permission>
  </div>
</template>

<script>
import tagsScreen from './components/tagsScreen';
import { request } from '@/network';
import { mapState } from 'vuex';
import { setInfoData, getMemberId } from '@/assets/public/utils/token';
import permission from '@/components/pc/permission.vue';
export default {
  name: 'tongzhi',
  components: {
    tagsScreen,
    permission
  },
  data () {
    return {
      show: false,
      tagsList: [],
      addTagList: {
        id: 5,
        name: '申报状态',
        children: [
          { name: '申报中', uuid: 'N' },
          { name: '已截止', uuid: 'Y' }
        ]
      },
      tagsDefaultUuid: {
        1: '',
        2: '',
        3: '',
        4: '',
        5: ''
      },
      sortType: '0',
      tongzhiList: [],
      pageListTotal: 0,
      pageSize: 10,
      currentPage: 1,
      keyWord: this.search,
      chooseCity: '',
      hasSearchThis: true,
      dialogVisiblePermission: false,
      typeDialog: 'Vip',
      firstRefresh: true,
      loading: false,
      flag: true,
      isFirst: true
    };
  },
  activated () {
    console.log('activated');
    if (this.isFirst) return;
    this.chooseCity = localStorage.getItem('selectCity');
    this.getTongzhiListData(this.currentPage);
  },
  mounted () {
    if (this.$route) {
      // 当前请求是通过 Vue Router 处理的
      console.log('当前请求是通过 Vue Router 处理的');
    } else {
      // 当前请求是通过传统的 HTTP 请求进入的
      console.log('当前请求是通过传统的 HTTP 请求进入的');
      window.location.reload();
    }
    console.log('mounted');
    this.chooseCity = localStorage.getItem('selectCity');
    this.getTongzhiListData(1);
    this.getTags().then(() => {
      this.initTagsList();
    });
  },
  computed: {
    ...mapState('policy', [ 'search', 'hasSearch' ]),
    ...mapState({
      selectCity: (state) => state.menu.selectCity
    })
  },
  methods: {
    async getTags () {
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/policylibrary/getTags'
      });
      if (res.code === 200) this.tagsList = res.data;
      return Promise.resolve();
    },
    showHide1 () {
      this.show = !this.show;
    },
    showHide2 (e, index) {
      const newO = this.tongzhiList[index];
      if (!newO.packUp) {
        e.currentTarget.previousElementSibling.className = 'foraml_wrap';
        e.currentTarget.innerHTML = '- 收起';
        e.currentTarget.previousElementSibling.style.marginBottom = 0;
        newO.packUp = true;
        this.$set(this.tongzhiList, index, newO);
      } else {
        e.currentTarget.previousElementSibling.className = 'foraml_wrap_hide';
        e.currentTarget.innerHTML = '+ 展开更多';
        newO.packUp = false;
        this.$set(this.tongzhiList, index, newO);
      }
    },
    initTagsList () {
      this.tagsList.push(this.addTagList);
      this.tagsList.forEach((item) => {
        item.children.unshift({ name: '不限', uuid: '' });
      });
    },
    tagsDefaultUuidHandle () {
      var uuidList = '';
      if (this.tagsDefaultUuid['1']) { uuidList += this.tagsDefaultUuid['1'] + ','; }
      if (this.tagsDefaultUuid['2']) { uuidList += this.tagsDefaultUuid['2'] + ','; }
      if (this.tagsDefaultUuid['3']) { uuidList += this.tagsDefaultUuid['3'] + ','; }
      if (this.tagsDefaultUuid['4']) { uuidList += this.tagsDefaultUuid['4'] + ','; }
      uuidList = uuidList.slice(0, uuidList.length - 1);
      return uuidList || '';
    },
    async getTongzhiListData (page) {
      this.loading = true;
      const postData = {
        pageNo: page,
        pageSize: this.pageSize,
        sortType: this.sortType,
        isEnd: '',
        city: this.chooseCity,
        memberId: getMemberId()
      };
      var uuidList = this.tagsDefaultUuidHandle();
      if (uuidList) postData.uuidList = uuidList;
      if (this.tagsDefaultUuid['5']) {
        if (this.tagsDefaultUuid['5'] === 'Y') {
          postData.isEnd = true;
        } else {
          postData.isEnd = false;
        }
      }
      postData.keyWord = this.search;
      const data = await request({
        method: 'POST',
        url: '/pcp/policylibrary/getNotice',
        data: postData
      });
      if (data.status === 500 && this.firstRefresh) window.location.reload(); // 如果接口返回的是500就自动刷新页面
      const isDetailBack = JSON.parse(localStorage.getItem('isDetailBack')); // 如果是detail页面跳转回来的话就不回到顶部
      if (!this.isFirst && !isDetailBack) {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
      localStorage.setItem('isDetailBack', false);
      const { data: res } = data;
      if (res.code === 200) {
        const { policyList, totalSize } = res.data;
        this.tongzhiList = policyList.map((r) => {
          r.packUp = false;
          return r;
        });
        this.tongzhiList.forEach(v => {
          v.title = v.title.replace(postData.keyWord, `<font color='#156ED0'>${postData.keyWord}</font>`);
        });
        this.pageListTotal = totalSize;
        this.currentPage = page;
        this.flag = policyList.length > 0;
        this.loading = false;
        this.isFirst = false;
      }
    },
    goToFormalDetail (id) {
      this.$router.push({
        path: '/formaldetails',
        query: {
          id
        }
      });
    },
    gotoJieDu (id, inputId) {
      setInfoData(request).then(resLogin => {
        if (resLogin !== 'islogin') {
          this.dialogVisiblePermission = true;
          this.typeDialog = 'Login';
        } else {
          this.$router.push({
            path: '/formaldetails/jiedu',
            query: {
              id,
              inputId
            }
          });
        }
      });
    },
    handleToggle () {
      this.dialogVisiblePermission = false;
    },
    handlePermission (type) {
      if (type === 'Login') {
        this.$router.push({ name: 'login' });
        this.dialogVisiblePermission = false;
      }
    }
    // style='background:#f8f8f8;border:1px solid #e7e7e7'
    // over(){

    // },
    // out(){},
  },
  watch: {
    search (newVal) {
      this.keyWord = newVal;
      this.hasSearchThis = newVal === '';
      this.getTongzhiListData(1);
    },
    selectCity: {
      handler (newVal) {
        this.chooseCity = newVal;
        if (!this.isFirst) this.getTongzhiListData(1);
      },
      immediate: true
    }
  }
};
</script>

<style lang="less" scoped>
.main {
  width: 1200px;
  min-height: 300px;
}

.main>div[data-v-97573b5a]:nth-child(1) {
  width: 130px;
  color: green !important;
  font-size: 14px;
}

.main>div[data-v-2db8a1c8] {
  //  margin: 0 -53px;

  box-sizing: border-box;
  // border-bottom: 10px solid #f9f8f9;
}

.line[data-v-2db8a1c8] {
  display: none;
}

.screens_hide {
  height: 85px;
  overflow: hidden;
  position: relative;
  padding-top: 20px;
  transition: height 0.5s;
  -webkit-transition: height 0.5s;

  /* Safari */
  &.screens_show {
    height: 245px;
  }
}

.more_saixuan {
  color: #156ED0;
  padding-top: 12px;
  font-size: 14px;
  cursor: pointer;

  >img {
    width: 12px;
    height: 12px;
    margin-right: 10px;
    transition: all 0.5s;

    &.rotate {
      transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -o-transform: rotate(180deg);
    }
  }
}

.sort_area {
  // color: #c2c2c2;
  font-size: 14px;
  margin-bottom: 20px;

  &>div {
    margin-right: 50px;

    /deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner {
      background-color: #fff;
      color: #156ED0;
      border-color: #156ED0;
    }

    /deep/.el-radio-button__inner:hover {
      color: #156ED0;
    }
  }
}

.zhengce_area {

  // border-top: 2px solid #d4d6da;
  >div {
    width: 100%;
  }

  .zhengce_title {
    height: 40px;
    line-height: 40px;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 700;

    cursor: pointer;

    >div:nth-child(1) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      // margin-left: 58px;
    }

    >div:nth-child(2) {
      color: #b2b2b2;
      display: none;
    }
  }

  .zhengce_info {
    // margin-left: 67px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    color: #b8b8b8;
    margin-bottom: 10px;
  }

  .foraml_wrap {
    overflow: hidden;
    height: auto;
    // margin-bottom: 40px;
  }

  // .foraml_wrap_up {
  //   overflow: hidden;
  //   height: auto
  // }
  .foraml_wrap_hide {
    overflow: hidden;
    height: 300px;
    margin-bottom: 0px;
  }

  .showHide {
    margin: 0 auto;
    width: 75px;
    text-align: center;
    padding: 16px 0;
    color: #156ED0;
    font-size: 14px;
    cursor: pointer;
  }

  // .formal :hover{
  //   border:1px solid #c8c8c8
  // }
  .formal {
    // width: 1100px;
    height: 50px;
    line-height: 50px;
    // border: 1px solid #e5e5e5;
    border-radius: 5px;
    margin: 5px 0 5px 0px;
    padding-left: 15px;
    padding-right: 15px;
    color: #585858;
    box-sizing: border-box;
    cursor: pointer;

    .left {
      width: 830px;
      position: relative;
      left: 110px;

      div:nth-child(1) {
        font-size: 16px;
        // margin-left: 100px;
        max-width: 640px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        //         position: relative;
        // left: 100px;
      }
    }

    .right {
      width: 300px;

      div:nth-child(1) {
        font-size: 14px;
      }

      div:nth-child(2) {
        text-align: right;
        width: 100px;
        font-size: 14px;
      }
    }
  }
}

.fuchifangshi {
  font-size: 14px;
  border-radius: 3px;
  line-height: 20px;
  height: 20px;
  line-height: 20px;
  padding: 3px 5px 3px 5px;
  color: #ffffff;

}

.type-1 {
  background: #fb9435;
}

.type-2 {
  background: #1f88f9;
}

.type-3 {
  background: #BF382F;
}

.type-4 {
  background: #2EBC66;
}

.type-5 {
  background: #6cc2ed;
}
.type-6 {
  background: rgba(0, 0, 0, 0.4);
}

.red {
  color: #ff0000;
}

.line {
  border-top: 2px solid #d4d6da;
  margin-bottom: 40px;
}

.el-pagination {
  text-align: center;
  padding-bottom: 50px;
}

.underline:hover {
  text-decoration: underline;
}

// .zhengce_area .formal{
//   display: flex;
//   flex-wrap: wrap;
// }
// .zhengce_area .formal >div{
//   flex: ;
// }
// .zhengce_area .formal:hover {

// }

// .zhengce_area .formal .right div[data-v-2db8a1c8]:last-child {
//     font-size: 14px;
//     position: relative;
//     left: -360%;
// }
.yjz {
  position: relative;
  white-space: nowrap;
  left: -854px;

}

.yjz2 {
  position: relative;
  white-space: nowrap;
  left:-854px;

}

.zijin {
  margin-left: 1px;
}

// .titlename{
//   margin-left: 106px;
// }
// .titlename2{
//   margin-left: 106px;
// }
#zgbt {
  white-space: nowrap;
  border: 1px solid rgb(255, 161, 66);
  color: rgb(255, 161, 66) !important;
  line-height: 26px;
  padding: 0px 3px;
  border-radius: 3px;
}

.jzsj {
  width: 200px !important;
  color: #b8b8b8;
}

.el-pagination[data-v-2db8a1c8] {
  border-bottom: 0 !important
}

// .zhengce_area  div:last-child{
//   border-bottom: 0 !important;
// }
// .main div:last-child{
//   border-bottom: 0 !important;
// }
.zhengce_area:last-child {
  border-bottom: 0 !important;
}

.gray_box {
  color: rgba(0, 0, 0, 0.4);
}

.black_box {
  color: #000;
}
// .divHover:hover{
//   // border:1px solid #156ED0
//   background: #156ED0;
// }
.tall{
  // height: 300px;
}
</style>
